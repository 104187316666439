import { SearchResponse } from "@/api/search";
import { SearchResultSanity } from "~/types/search";
import React, {
  type Dispatch,
  type SetStateAction,
  type PropsWithChildren,
  useMemo,
  useState,
} from "react";
import { useSite } from "@hooks/useSite";
import { type SearchResult } from "@bared/ui";

export type SearchContextValues = {
  searchTerm: string;
  totalProductCount: number;
  isLoading: boolean;
  sanitySearchResults?: SearchResultSanity[];
  setSanitySearchResults?: Dispatch<
    SetStateAction<SearchResultSanity[] | undefined>
  >;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  searchResults: SearchResult[];
  getSearchResults: (query: string) => Promise<SearchResult[]>;
};

export const SearchContext = React.createContext<SearchContextValues>({});

export const SearchProvider = ({ children }: PropsWithChildren) => {
  const site = useSite();

  const [sanitySearchResults, setSanitySearchResults] =
    useState<SearchResultSanity[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [totalProductCount, setTotalProductCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getSearchResults = async (query: string) => {
    setIsLoading(true);

    try {
      const response = await fetch("/api/search", {
        method: "POST",
        headers: {
          "X-Site-Handle": site.handle,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchTerm: query,
        }),
      });

      if (!response.ok) {
        throw new Error("Search failed");
      }

      const data = await response.json();
      const { searchedProducts, totalProductCount } = data as SearchResponse;

      setSearchResults(searchedProducts);
      setTotalProductCount(totalProductCount);

      return searchedProducts;
    } catch (error) {
      console.error("Failed to search products:", error);
      setSearchResults([]);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const values = useMemo(
    () => ({
      sanitySearchResults,
      setSanitySearchResults,
      searchTerm,
      setSearchTerm,
      searchResults,
      getSearchResults,
      totalProductCount,
      isLoading,
    }),
    [
      sanitySearchResults,
      setSanitySearchResults,
      searchTerm,
      setSearchTerm,
      searchResults,
      getSearchResults,
      totalProductCount,
      isLoading,
    ],
  );

  return (
    <SearchContext.Provider value={values}>{children}</SearchContext.Provider>
  );
};
