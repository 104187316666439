import { Search } from "@usereactify/search";
import React from "react";
import tw, { styled } from "twin.macro";
import { Icon } from "../../Icon";
import { SearchFormResults } from "./SearchFormResults";
import { withSearchForm } from "./withSearchForm";
import { ProductSearch } from "@bared/ui";

const Wrapper = styled.div`
  ${tw`relative h-full`}
`;

const InputWrapper = styled.div`
  ${tw`relative flex-1`}
  input {
    ${tw`w-full text-h4 text-left text-primary leading-input px-mega h-giga py-zero border-0 outline-none bg-transparent md:text-center md:text-[32px] md:h-[86px] focus:outline-none`}
    font-family: Harriet Display,sans-serif !important;
  }
`;

const StyledDesktopIcon = tw(
  Icon,
)`hidden transition-layout -mx-micro text-primary py-micro lg:px-mini md:block`;

const StyledMobileIcon = tw(
  Icon,
)`block transition-layout py-micro -mx-micro text-primary lg:px-mini md:hidden`;

const CloseIconWrapper = styled.div`
  ${tw`absolute cursor-pointer right-mini top-[13px] md:top-midi md:right-midi`}
  ${({ display }) => (display === "true" ? tw`z-20` : "")}
`;

const SearchIconWrapper = styled.div`
  ${tw`absolute cursor-pointer left-mini top-[13px] md:top-midi md:left-midi`}
  ${({ display }) => (display === "true" ? tw`z-20` : "")}
`;

export const SearchForm = withSearchForm(
  ({
    handleViewAllClick,
    totalProductCount,
    activeSearch,
    onSearchClose,
    searchTerm,
    sanitySearchResults,
    handleSearchChange,
    searchProducts,
    searchResults,
    newSearchTerm,
    isLoading,
  }) => {
    return (
      <>
        {process.env["GATSBY_FEATURE_SEARCH"] ? (
          <>
            <ProductSearch
              label="Search"
              placeholder="Search products..."
              value={newSearchTerm}
              onChange={handleSearchChange}
              onSearch={searchProducts}
              searchResults={searchResults}
              onSearchClose={onSearchClose}
              handleViewAllClick={handleViewAllClick}
              totalProductCount={totalProductCount}
              isLoading={isLoading}
            />
            <div
              onClick={onSearchClose}
              className="left-0 right-0 bottom-0 fixed top-[96px] cursor-pointer bg-primary-500"
            />
          </>
        ) : (
          <Wrapper>
            <InputWrapper className="search-input-wrapper">
              <Search />
              <CloseIconWrapper
                onClick={onSearchClose}
                display={searchTerm?.length > 0 ? "false" : "true"}
                data-testid="header-search-close-trigger"
              >
                <StyledDesktopIcon name="cross" size="small" />
                <StyledMobileIcon name="cross" size="xsmall" />
              </CloseIconWrapper>
              <SearchIconWrapper>
                <StyledDesktopIcon name="searchThin" size="small" />
                <StyledMobileIcon name="searchThin" size="xsmall" />
              </SearchIconWrapper>
              <SearchFormResults
                active={activeSearch}
                sanitySearchResults={sanitySearchResults}
              />
            </InputWrapper>
          </Wrapper>
        )}
      </>
    );
  },
);
