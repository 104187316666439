import React, { useContext, FC, useEffect, useRef } from "react";

import { useSearch } from "@usereactify/search";
import { useDebounce } from "@react-hooks-library/core";

import { useAnalytics } from "~/hooks/useAnalytics";
import { useApp } from "~/hooks/useApp";
import { useSanitySearch } from "~/hooks/useSanitySearch";
import { useLocalisation } from "~/hooks/useLocalisation";
import { ComponentProps } from "~/types/components";
import { SearchResultSanity } from "~/types/search";

import { SearchContext } from "~/providers/SearchProvider";
import { type SearchResult } from "@bared/ui";

type SearchFormInputProps = ComponentProps;

type SearchFormOutputProps = {
  activeSearch: boolean;
  sanitySearchResults: SearchResultSanity[];
  searchTerm: string;
  newSearchTerm: string;
  searchResults: SearchResult[];
  totalProductCount: number;
  isLoading: boolean;
  onSearchClose: () => void;
  searchProducts: (query: string) => Promise<SearchResult[]>;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleViewAllClick: () => void;
};

export const withSearchForm =
  (Component: FC<SearchFormOutputProps>) =>
  ({ name = "SearchForm" }: SearchFormInputProps) => {
    const {
      searchTerm: newSearchTerm,
      setSearchTerm: setNewSearchTerm,
      getSearchResults,
      searchResults,
      totalProductCount,
      isLoading,
    } = useContext(SearchContext);

    const { globalStateReducer } = useApp();
    const { searchTerm, setSearchTerm } = useSearch();
    const { trackSearch } = useAnalytics();
    const { sanitySearchResults } = useSanitySearch();
    const { navigate } = useLocalisation();
    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    const [{ activeSearch }, dispatch] = globalStateReducer;
    const displaySearchResults =
      searchResults && totalProductCount > 6
        ? searchResults.slice(0, 6)
        : searchResults;

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      getSearchResults(event.target.value);
      setNewSearchTerm(event.target.value);
    };

    const handleSearchClose = () => {
      document.body.style.removeProperty("overflow");
      dispatch({
        type: "setActiveSearch",
        payload: !activeSearch,
      });
    };

    const handleViewAllClick = () => {
      navigate(`/search?q=${newSearchTerm}`);
      handleSearchClose();
    };

    useEffect(() => {
      trackSearch(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    useEffect(() => {
      if (activeSearch) {
        document.body.style.overflow = "hidden";

        setSearchTerm(undefined);
        document
          .querySelector<HTMLInputElement>(".search-input-wrapper input")
          ?.focus();
      }
    }, [activeSearch]);

    Component.displayName = name;

    return (
      <Component
        activeSearch={activeSearch}
        onSearchClose={handleSearchClose}
        sanitySearchResults={sanitySearchResults}
        searchTerm={searchTerm}
        newSearchTerm={newSearchTerm}
        searchProducts={getSearchResults}
        searchResults={displaySearchResults}
        handleViewAllClick={handleViewAllClick}
        totalProductCount={totalProductCount}
        handleSearchChange={handleSearchChange}
        isLoading={isLoading}
      />
    );
  };
